/*@import '~ngx-toasta/styles/style-bootstrap.css';*/
@import 'ngx-toasta/styles/style-material.css';
/*@import '~@swimlane/ngx-datatable/index.scss';
@import '~@swimlane/ngx-datatable/themes/material.css';*/
@import '@swimlane/ngx-datatable/assets/icons.css';
@import 'bootstrap-toggle/css/bootstrap-toggle.css';
/*@import "@ng-select/ng-select/themes/default.theme.css";*/

/*@import "~@fortawesome/fontawesome-free/css/fontawesome.min.css";*/
/*@import "~@ng-select/ng-select/themes/default.theme.css";*/
/*@import '~bootstrap-select/dist/css/bootstrap-select.css';
@import '~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css';*/
/*@import '~font-awesome/css/font-awesome.css';*/
@import 'alertify.core.css';
@import 'alertify.bootstrap.css';
@import 'alertify.default.css';
/*@import 'bootstrap4-compatibility.css';*/

.alertify {
  top: 75px;
}

.alertify-message {
  font-size: 1.1rem;
}

#toasta.toasta-position-top-right {
  top: 80px !important;
  right: 20px;
}

#toasta .toast .toasta-theme-material {
  font-size: 0.9em !important;
}

  #toasta .toast.toasta-theme-material .toast-text .toast-title {
    font-size: 1.02em !important;
  }

#toasta .toast-text.toast-msg {
  max-width: 1000px !important;
  max-height: 800px !important;
  display: inline-block !important;
  overflow: auto !important;
}

.form-group .icon-addon .form-control {
  border-radius: 25px;
}

.icon-addon {
  position: relative;
  color: #555;
  display: block;
}

  .icon-addon:after,
  .icon-addon:before {
    display: table;
    content: " ";
  }

  .icon-addon:after {
    clear: both;
  }

  .icon-addon.addon-md .fa,
  .icon-addon .fa {
    position: absolute;
    z-index: 2;
    font-size: 1rem;
    width: 20px;
    text-align: center;
    padding: 10px 0;
    top: 1px;
  }

    .icon-addon .fa.left-icon {
      left: 10px;
      margin-left: -2.5px;
    }

    .icon-addon .fa.right-icon {
      right: 10px;
      margin-right: -2.5px;
    }

  .icon-addon.addon-lg .form-control {
    line-height: 1.33;
    height: 46px;
    font-size: 18px;
  }

    .icon-addon.addon-lg .form-control.left-icon {
      padding-top: 10px;
      padding-right: 16px;
      padding-bottom: 10px;
      padding-left: 40px !important;
    }

    .icon-addon.addon-lg .form-control.right-icon {
      padding-top: 10px;
      padding-right: 40px !important;
      padding-bottom: 10px;
      padding-left: 16px;
    }

  .icon-addon.addon-sm .form-control {
    height: 30px;
    font-size: 0.875rem;
    line-height: 1.5;
  }

    .icon-addon.addon-sm .form-control.left-icon {
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 28px !important;
    }

    .icon-addon.addon-sm .form-control.right-icon {
      padding-top: 5px;
      padding-right: 28px !important;
      padding-bottom: 5px;
      padding-left: 10px;
    }

  .icon-addon.addon-lg .fa {
    font-size: 18px;
    top: 4px;
  }

    .icon-addon.addon-lg .fa.left-icon {
      margin-left: 0;
      left: 11px;
    }

    .icon-addon.addon-lg .fa.right-icon {
      margin-right: 0;
      right: 11px;
    }

  .icon-addon.addon-md .form-control,
  .icon-addon .form-control {
    font-weight: normal;
  }

    .icon-addon.addon-md .form-control.left-icon,
    .icon-addon .form-control.left-icon {
      float: left;
      padding-left: 30px;
    }

    .icon-addon.addon-md .form-control.right-icon,
    .icon-addon .form-control.right-icon {
      float: right;
      padding-right: 30px;
    }

  .icon-addon.addon-sm .fa {
    font-size: 12px;
    top: -1px;
  }

    .icon-addon.addon-sm .fa.left-icon,
    .icon-addon.addon-sm .glyphicon.left-icon {
      margin-left: 0;
      left: 5px;
    }

    .icon-addon.addon-sm .fa.right-icon,
    .icon-addon.addon-sm .glyphicon.right-icon {
      margin-right: 0;
      right: 5px;
    }

  .icon-addon .form-control:focus + .glyphicon,
  .icon-addon:hover .glyphicon,
  .icon-addon .form-control:focus + .fa,
  .icon-addon:hover .fa {
    color: #2580db;
  }

.clear-input {
  text-decoration: none !important;
  color: #b3b3b3 !important;
}

  .clear-input:hover {
    color: #2580db !important;
  }
