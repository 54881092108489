@use '@angular/material' as mat;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

/*@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';*/
/*@import 'bootstrap/scss/mixins';*/
/*@import 'bootstrap/scss/grid';*/

/*@import '../../../assets/styles/dx.material.custom.css';*/
/*@import '../../../assets/styles/ng-select';*/
@import '../../../assets/styles/styles-vendor.css';
/*@import "~@ng-select/ng-select/themes/default.theme.css";*/
/*@import '../../../assets/styles/custom-vendor';
@import '~bootstrap/scss/bootstrap';

@import '../../../assets/styles/vertical-tabs';
@import '../../../assets/styles/ngx-datatable';
@import '../../../assets/styles/styles-vendor.css';*/
// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';
@import './angular-material';


@include mat.core();

$my-primary: mat.define-palette(mat.$indigo-palette, 500);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

$my-theme: mat.define-light-theme(( color: ( primary: $my-primary, accent: $my-accent, ), typography: mat.define-typography-config(), density: 0, ));

@include mat.all-component-themes($my-theme);

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();

  // loading progress bar
  @include ngx-pace-theme();

 /* @include ng-select-theme();*/

  @include nb-overrides();
};
